<template>
    <div>
        <div>
            <v-dialog v-model="editActivity_dialog" max-width="500px">
  <v-card>
    <v-card-title>
      <span class="headline">Edit Activity</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="form" lazy-validation>
                <v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-text-field v-model="editActivity_currentItem.name" label="Name" type="text" :disabled="false"></v-text-field>
  </v-col>
</v-row>
    <v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-textarea v-model="editActivity_currentItem.details" label="Details" :disabled="false" auto-grow></v-textarea>
  </v-col>
</v-row>
    <v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-text-field v-model="editActivity_currentItem.order" label="Order" type="text" :disabled="false"></v-text-field>
  </v-col>
</v-row>
    <v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-autocomplete
      :items="items"
      v-model="editActivity_currentItem.parent"
      item-text="displayName"
      item-value="id"
      label="Parent"
      @change=""
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
    <v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-autocomplete
      :items="items"
      v-model="editActivity_currentItem.discoveredBy"
      item-text="displayName"
      item-value="id"
      label="Discovered By"
      @change=""
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
    <v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-autocomplete
      :items="elements"
      v-model="editActivity_currentItem.element"
      item-text="name"
      item-value="id"
      label="Element"
      @change=""
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
        <v-row>
      <v-col cols="12" sm="12">
        Inputs
        <v-row v-for="(thisItem, index) in editActivity_currentItem.inputs">
            <v-col cols="7" sm="7">
    <v-text-field
      label="Name"
      name="name"
      v-model="thisItem.name"
      type="text"
    ></v-text-field>
  </v-col>
            <v-col>
            <v-btn small v-if="editActivity_currentItem.id" color="primary" dark class="mt-6"
              @click="editActivity_saveprocessActivityInput(index)"><v-icon>mdi-thumb-up</v-icon></v-btn>
            <v-btn small v-if="true" color="error" dark class="mt-6"
              @click="editActivity_deleteprocessActivityInput(index)">-</v-btn>
          </v-col>
        </v-row>
        <v-row>
        <v-btn small v-if="true" color="primary" dark @click="editActivity_addprocessActivityInput">+</v-btn>
        </v-row>
      </v-col>
    </v-row>

    Inputs Values
    <v-row v-for="(input, index) in editActivity_currentItem.inputsValues">
  <v-col cols="12" sm="12">
    {{input.name}}
  </v-col>
      <v-col cols="12" sm="12">
    <v-autocomplete
      :items="['absolute', 'describedBy', 'reference']"
      label="Type"
      v-model="input.value.type.id"
      @change="onChangeValueType(editActivity_currentItem.inputsValues, index)"
      clearable
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="12" v-if="input.value.type.id == 'absolute'">
    <v-text-field
      label="Value"
      name="absoluteValueValue"
      v-model="input.value.type.absolute.value"
      type="text"
    ></v-text-field>
  </v-col>

  <v-col cols="12" sm="12" v-if="input.value.type.id == 'describedBy'">
    <v-text-field
      label="Value"
      name="describedByValueValue"
      v-model="input.value.type.describedBy.value"
      type="text"
    ></v-text-field>
  </v-col>

  <v-col cols="12" sm="12" v-if="input.value.type.id == 'reference'">
    <v-autocomplete
      :items="['input']"
      label="Reference Type"
      @change="onChangeReferenceType(editActivity_currentItem.inputsValues, index)"
      v-model="input.value.type.reference.type"
      clearable
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="12" v-if="input.value.type.reference.type == 'input'">
    <v-autocomplete
      :items="[]"
      label="Input"
      v-model="input.value.type.reference.input"
      item-text="name"
      item-value="id"
      return-object
      clearable
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="12" v-if="input.value.type.reference.type == 'outcome'">
    <v-autocomplete
      :items="[]"
      label="Outcome"
      v-model="input.value.type.reference.outcome"
      item-text="displayName"
      item-value="id"
      return-object
      clearable
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="12">
    <v-text-field
      label="Transformed By"
      name="transformedBy"
      v-model="input.value.transformedBy"
      type="text"
    ></v-text-field>
  </v-col>
</v-row>

        <v-row>
      <v-col cols="12" sm="12">
        Outcomes
        <v-row v-for="(thisItem, index) in editActivity_currentItem.outcomes">
            <v-col cols="12" sm="12">
    <v-text-field
      label="Name"
      name="name"
      v-model="thisItem.name"
      type="text"
    ></v-text-field>
  </v-col>
  <v-col cols="12" sm="12">
    <v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-autocomplete
      :items="editActivity_triggerItems"
      v-model="thisItem.trigger"
      item-text="displayName"
      item-value="id"
      label="Trigger"
      @change=""
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
  </v-col>
            <v-col>
            <v-btn small v-if="editActivity_currentItem.id" color="primary" dark class="mt-6"
              @click="editActivity_saveprocessActivityOutcome(index)"><v-icon>mdi-thumb-up</v-icon></v-btn>
            <v-btn small v-if="true" color="error" dark class="mt-6"
              @click="editActivity_deleteprocessActivityOutcome(index)">-</v-btn>
          </v-col>
        </v-row>
        <v-row>
        <v-btn small v-if="true" color="primary" dark @click="editActivity_addprocessActivityOutcome">+</v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
  <v-col cols="12" sm="12">
    <v-autocomplete
      :items="[{id: 'notStarted'}, {id: 'inProgress'}, {id: 'completed'}, {id: 'ignored'}]"
      label="Status"
      v-model="editActivity_currentItem.status"
      item-text="id"
      item-value="id"
      return-object
      clearable
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="12" v-if="editActivity_currentItem.status.id == 'completed'">
    <v-autocomplete
      :items="editActivity_currentItem.outcomes"
      label="Outcome"
      v-model="editActivity_currentItem.status.outcome"
      item-text="name"
      item-value="id"
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
    </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn small v-if="true" color="error" dark class="mr-2"  @click="editActivity_close()">Cancel</v-btn>
    <v-btn small v-if="true" color="primary" dark class="mr-2"  @click="editActivity_save()">Save</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
            <v-dialog v-model="createProcessActivity_dialog" max-width="500px">
  <v-card>
    <v-card-title>
      <span class="headline">Create Activity</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="form" lazy-validation>
            <v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-text-field v-model="createProcessActivity_currentItem.name" label="Name" type="text" :disabled="false"></v-text-field>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-textarea v-model="createProcessActivity_currentItem.details" label="Details" :disabled="false" auto-grow></v-textarea>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-text-field v-model="createProcessActivity_currentItem.order" label="Order" type="text" :disabled="false"></v-text-field>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-autocomplete
      :items="items"
      v-model="createProcessActivity_currentItem.parent"
      item-text="displayName"
      item-value="id"
      label="Parent"
      @change=""
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-autocomplete
      :items="items"
      v-model="createProcessActivity_currentItem.discoveredBy"
      item-text="displayName"
      item-value="id"
      label="Discovered By"
      @change=""
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn small v-if="true" color="error" dark class="mr-2"  @click="createProcessActivity_close()">Cancel</v-btn>
    <v-btn small v-if="true" color="primary" dark class="mr-2"  @click="createProcessActivity_save()">Save</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
    <div>
    <v-toolbar flat color="white">
      <v-toolbar-title>Users</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-text-field
    v-model="search"
    append-icon="search"
    label="Search"
    single-line
    hide-details
  ></v-text-field>
  <v-spacer></v-spacer>
        <v-checkbox v-model="isTriggered" label="TriggeredOnly" @change="onTriggeredChange"></v-checkbox>
        <v-combobox
            dense
          multiple
          small-chips
          @change="onChangeStatus"
          :items="['notStarted', 'inProgress', 'completed', 'ignored']"
            v-model="selectedStatuses"
            clearable
        ></v-combobox>
    
        <v-btn small v-if="true" color="primary" dark class="mr-2"  @click="createProcessActivity_createItem">Create Activity</v-btn>
    </v-toolbar>
    <div>
        <v-treeview v-if="isTreeView" :items="treeItems" :load-children="loadChildren" :open.sync="isOpen" open-all>
            <template v-slot:label="{ item }">
              {{item.name}}
            </template>
        </v-treeview>
    </div>
    <v-data-table
      :items-per-page="-1"
      :headers="tableHeaders"
      :items="items"
      class="elevation-1"
      :loading="loading"
      :search="search"
      v-if="!isTreeView"
    >
      <template v-slot:item.displayName="{ item }">
            <v-row>
                <v-col :offset-md="getOffset(item)">
                    <v-btn icon v-if="true" @click="refreshItem(item)">
                      <v-icon
                      >
                        mdi-refresh
                      </v-icon>
                    </v-btn>
                    <v-btn icon v-if="shouldShowExpand(item)" @click="handleExpand(item)">
                      <v-icon
                      >
                        mdi-arrow-right-drop-circle
                      </v-icon>
                    </v-btn>
                    <v-btn icon v-if="shouldShowCompress(item)" @click="handleCompress(item)">
                      <v-icon
                      >
                        mdi-arrow-down-drop-circle
                      </v-icon>
                    </v-btn>
                    <v-btn disabled icon v-if="!shouldShowExpand(item) && !shouldShowCompress(item)">
                      <v-icon
                      >
                      </v-icon>
                    </v-btn>
                    
                    {{ item.displayName }}
                    
                </v-col>
                            
                            
            </v-row>
      </template>

      <template v-slot:item.action="{ item }">
            <v-btn small v-if="item.type == 'activity'" color="primary" dark class="mr-2"  @click="editActivity_createItem(item)">Edit</v-btn>
    <v-btn small v-if="item.type == 'activity'" color="error" dark class="mr-2"  @click="deleteItem(item)">Delete</v-btn>
      </template>
      <template v-slot:no-data>No Processes</template>
    </v-data-table>
  </div>
    </div>
    </div>
</template>
<script>
      import Multipleadd2 from './multipleadd2.vue'
  import Multipleadd from './multipleadd.vue'
  export default {
      components: {
          Multipleadd, Multipleadd2
      },
      props: {
          
      },
      data: () => ({
          loading: false,
          search: null,
          items: [],
          treeItems: [],
          isTreeView: false,
          isOpen: [],
          isOpenHandled: [],
          selectedStatuses: [],
          isTriggered: false,
          elements: [],
          editActivity_currentItem: {inputs: [], outcomes: [], status: {outcome: {}}},
editActivity_dialog: false,
createProcessActivity_currentItem: {parent: {}},
createProcessActivity_dialog: false
      }),
      watch: {
        selectedStatuses(value) {
            
        },
        isTreeView(value) {
            localStorage.isTreeView = value
            this.updateTreeView()
        },
        isOpen(value) {
            localStorage.isOpen = JSON.stringify(value)
            
        },
        isOpenHandled(value) {
            
        }
      },
      computed: {
          tableHeaders() {
            return [
                {"text": "id", "value": "id"},
                {"text": "name", "value": "displayName"},
                {"text": "orderKeyPath", "value": "orderKeyPath"},
                {"text": "type", "value": "status"},
                { text: "Options", value: "action", sortable: false }
              ]
          },
          editActivity_triggerItems() {
    var item = this.editActivity_currentItem
    var items = this.items
    if(item.parent && item.parent.id) {
        items = items.filter((x) => { return x.parent && x.parent.id == item.parent.id })
    } else {
        items = items.filter((x) => {
            if(x.parent && x.parent.id) {
                return x.parent.id == null
            } else {
                return true
            }
        })
    }
    return items.filter((x) => { return x.id != this.editActivity_currentItem.id })
}
          
      },
      created() {
        this.initialize();
      },
      methods: {
          onTriggeredChange() {
            localStorage.isTriggered = this.isTriggered ? "1" : "0"
            this.initialize()
          },
          onChangeStatus() {
            localStorage.selectedStatuses = JSON.stringify(this.selectedStatuses)
            this.initialize()
          },
          refreshItem(item) {
            this.removeItemsByParentID(item.id)
            this.disableOpenByID(item.id)
          },
          removeItemsByParentID(parentID) {
            var children = this.items.filter((item) => { return item.parent && item.parent.id == parentID })
            for(var i = 0; i < children.length; i++) {
                var child = children[i]
                this.removeItemsByParentID(child.id)
                var index = this.findByID(child.id)
                this.items.splice(index, 1)
                this.disableOpenByID(child.id)
            }
          },
          addChildrenByID(id) {
            return this.fetchChildren(id).then((children) => {
                if(this.selectedStatuses.length > 0) {
                    children = children.filter((item) => {
                        if(item.status && item.status.id){}
                        else { return false }
                        return this.selectedStatuses.includes(item.status.id)
                    })
                }
                if(this.isTriggered) {
                    children = children.filter((x) => { return x.isTriggered })
                }
                var index = this.findByID(id)
                this.items.splice(index + 1, 0, ...children)
                return children
            })
          },
          shouldShowExpand(item) {
            return !this.isExpanded(item)
          },
          shouldShowCompress(item) {
            return this.isExpandedHandled(item) && item.hasChildren
          },
          isExpanded(item) {
            return this.isOpen.indexOf(item.id) !== -1
          },
          isExpandedHandled(item) {
            var index = this.isOpen.indexOf(item.id)
            return this.isOpenHandled[index]
          },
          isOpenByID(id) {
            var index = this.isOpen.findIndex((itemID) => { return id === itemID })
            if(index >= 0) {
                return true
            } else {
                return false
            }
          },
          disableOpenByID(id) {
            var index = this.isOpen.findIndex((itemID) => { return itemID === id })
            if(index >= 0) {
                this.isOpen.splice(index, 1)
                this.isOpenHandled.splice(index, 1)
            }
          },
          isOpenHandledByID(id) {
            var index = this.isOpen.findIndex((itemID) => { return itemID === id })
            if(index >= 0) {
                return this.isOpenHandled[index]
            } else {
                return false
            }
          },
          findByID(id) {
            return this.items.findIndex((item) => { return item.id === id })
          },
          findByParentID(id) {
            return this.items.findIndex((item) => {
                if(item.parent && item.parent.id) {
                    return item.id === id
                } else {
                    return false
                }
                
            })
          },
          handleCompress(item) {
            this.removeItemsByParentID(item.id)
            const index = this.isOpen.indexOf(item.id);
            if (index > -1) {
              this.isOpen.splice(index, 1);
              this.isOpenHandled.splice(index, 1)
            }
          },
          handleExpand(item) {
            this.isOpen.splice(this.isOpen.length + 1)
            this.isOpenHandled.splice(this.isOpenHandled.length + 1)
            this.$set(this.isOpen, this.isOpen.length, item.id)
            this.$set(this.isOpenHandled, this.isOpenHandled.length, false)
            this.updateIsOpen(item.id)
          },
          getOffset(item) {
            if(item.orderKeyPath) {} else { return 0 }
            var level = item.orderKeyPath.split(".").length
            return level - 1
          },
          updateTreeView() {
            this.isTreeView = localStorage.isTreeView === 'true'
          },
          getIndex(id) {
            if(id) {
                return this.findByID(id)
            } else {
                return -1
            }
          },
          updateIsOpen(id) {
            var index = this.getIndex(id)
            if((id == null) || this.isOpenByID(id) && !this.isOpenHandledByID(id)) {
                return this.addChildrenByID(id).then((c) => {
                    var promises = c.map((item) => {
                        return this.updateIsOpen(item.id)
                    })
                    return Promise.all(promises).then(() => {
                        index = this.getIndex(id)
                        if(index >= 0) {
                            var newItem = Object.assign({}, this.items[index], {hasChildren: c.length > 0})
                            this.$set(this.items, index, newItem)
                            var isOpenIndex = this.isOpen.findIndex((itemID) => { return id === itemID })
                            this.isOpenHandled.splice(isOpenIndex, 1, true)
                        }
                    })
                })
            }
          },
          fetchChildren(parentID) {
            if(parentID) {
            } else {
                parentID = "null"
            }
            return this.$apiClient.restRequest("get", "processActivity", null, null, "parentID=" + parentID)
            .then(response => {
                return response.data
            }) .catch(error => {
                console.log(error)
                console.log(error.response);
            });
          },
          loadChildren(parentItem) {
            return this.fetchChildren(parentItem.id).then((children) => {
                if(children.length > 0) {
                    parentItem.children.push(...children)
                }
            })
          },
          deleteItem(item) {
              confirm("Are you sure you want to delete this item?") && (() => {
              let id = item.id;
              this.loading = true;
              this.$apiClient .restRequest("delete", "processActivity", id)
              .then(response => {
                  this.loading = false; this.initialize();
              }) .catch(error => {
                  this.loading = false;
                  console.log(error)
                  console.log(error.response.data.error_description);
              });
          })();
          },
          initialize() {
            this.items = []
              this.$apiClient .restRequest("get", "element")
              .then(response => {
                  this.elements = response.data
              }) .catch(error => {
                  this.loading = false;
                  console.log(error)
                  console.log(error.response.data.error_description);
              });
              this.$apiClient .restRequest("get", "processActivity")
              .then(response => {
                  this.items = response.data
              }) .catch(error => {
                  this.loading = false;
                  console.log(error)
                  console.log(error.response.data.error_description);
              });
            if(localStorage.isOpen) {
                this.isOpen = JSON.parse(localStorage.isOpen)
            }

            if(localStorage.selectedStatuses) {
                this.selectedStatuses = JSON.parse(localStorage.selectedStatuses)
            }

            if(localStorage.isTriggered) {
                this.isTriggered = localStorage.isTriggered === "1"
            }
            
            this.isOpenHandled = this.isOpen.map((x) => { return false })
            // this.updateIsOpen()
          },
                editActivity_addprocessActivityInput() {
        this.editActivity_currentItem.inputs.push({})
      },
      editActivity_saveprocessActivityInput(index) {
        let item = this.editActivity_currentItem.inputs[index]
        this.$apiClient.restRequest("create", "processActivityInput", this.editActivity_currentItem.id, item)
        .then(response => {
            this.editActivity_loadData()
        }).catch(error => {
            this.loading = false;
            console.log(error);
        });
      },
      editActivity_deleteprocessActivityInput(index) {
        let item = this.editActivity_currentItem.inputs[index]
        var promise;
        if(item.id) {
            promise = this.$apiClient.restRequest("delete", "processActivityInput/" + item.id)
            .then(response => {
                this.editActivity_loadData()
            }).catch(error => {
                this.loading = false;
                console.log(error);
            });
        } else {
            this.editActivity_currentItem.inputs.splice(index, 1)
            promise = Promise.resolve()
        }
        return promise.then(this.editActivity_loadData)
      },
  onChangeValueType(array, index) {
        var item = array[index]
        if(item.value.type.id == 'absolute') {
            if(item.value.type.absolute) {}
            else { item.value.type.absolute = {} }
        } else if(item.value.type.id == 'describedBy') {
            if(item.value.type.describedBy) {}
            else { item.value.type.describedBy = {} }
        } else if(item.value.type.id == 'reference') {
            if(item.value.type.reference) {}
            else { item.value.type.reference = {} }
            if(item.value.type.reference.outcome) {}
            else { item.value.type.reference.outcome = {} }
            if(item.value.type.reference.input) {}
            else { item.value.type.reference.input = {} }
        }
        this.$set(array, index, item)
  },
  onChangeReferenceType(array, index) {
        var item = array[index]
        if(item.value.type.reference.type == 'input') {
            if(item.value.type.reference.input) {}
            else { item.value.type.reference.input = {} }
        } else if(item.value.type.reference.type == 'outcome') {
            if(item.value.type.reference.outcome) {}
            else { item.value.type.reference.outcome = {} }
        }
        this.$set(array, index, item)
  },
      editActivity_addprocessActivityOutcome() {
        this.editActivity_currentItem.outcomes.push({type: {activity: {}, process: {}}})
      },
      editActivity_saveprocessActivityOutcome(index) {
        let item = this.editActivity_currentItem.outcomes[index]
        this.$apiClient.restRequest("create", "processActivityOutcome", this.editActivity_currentItem.id, item)
        .then(response => {
            this.editActivity_loadData()
        }).catch(error => {
            this.loading = false;
            console.log(error);
        });
      },
      editActivity_deleteprocessActivityOutcome(index) {
        let item = this.editActivity_currentItem.outcomes[index]
        var promise;
        if(item.id) {
            promise = this.$apiClient.restRequest("delete", "processActivityOutcome/" + item.id)
            .then(response => {
                this.editActivity_loadData()
            }).catch(error => {
                this.loading = false;
                console.log(error);
            });
        } else {
            this.editActivity_currentItem.outcomes.splice(index, 1)
            promise = Promise.resolve()
        }
        return promise.then(this.editActivity_loadData)
      },
editActivity_loadData() {
    this.$apiClient .restRequest("get", "processActivity", this.editActivity_currentItem.id).then((response) => {
    this.editActivity_currentItem = response.data
    var inputsValues = response.data.inputsValues
        inputsValues = inputsValues.map((x) => {
            if(x.value) {}
    else { x.value = {} }
    if(x.value.type) {}
    else { x.value.type = {} }
    if(x.value.type.absolute) {}
    else { x.value.type.absolute = {} }
    if(x.value.type.describedBy) {}
    else { x.value.type.describedBy = {} }
    if(x.value.type.reference) {}
    else { x.value.type.reference = {} }
    if(x.value.type.reference.outcome) {}
    else { x.value.type.reference.outcome = {} }
    if(x.value.type.reference.input) {}
    else { x.value.type.reference.input = {} }
        return x
    })
    this.editActivity_currentItem.inputsValues = inputsValues
})
},
editActivity_createItem(item) {
    this.editActivity_dialog = true;
    this.editActivity_currentItem = Object.assign({}, item);
    this.$apiClient .restRequest("get", "processActivity", item.id).then((response) => {
    this.editActivity_currentItem = response.data
    var inputsValues = response.data.inputsValues
        inputsValues = inputsValues.map((x) => {
            if(x.value) {}
    else { x.value = {} }
    if(x.value.type) {}
    else { x.value.type = {} }
    if(x.value.type.absolute) {}
    else { x.value.type.absolute = {} }
    if(x.value.type.describedBy) {}
    else { x.value.type.describedBy = {} }
    if(x.value.type.reference) {}
    else { x.value.type.reference = {} }
    if(x.value.type.reference.outcome) {}
    else { x.value.type.reference.outcome = {} }
    if(x.value.type.reference.input) {}
    else { x.value.type.reference.input = {} }
        return x
    })
    this.editActivity_currentItem.inputsValues = inputsValues
})
},
  editActivity_close() {
    this.editActivity_dialog = false;
  },
  editActivity_save() {
    let body = this.editActivity_currentItem;
    this.loading = true;
    this.$apiClient.restRequest("create", "processActivity", null, body)
    .then(response => {
        this.loading = false;
        this.editActivity_dialog = false;
        this.initialize();
    }) .catch(error => {
        this.loading = false;
        console.log(error.response);
    });
  },
createProcessActivity_createItem(item) {
    this.createProcessActivity_dialog = true;
    this.createProcessActivity_currentItem = Object.assign({parent: {}}, item);
    
},
  createProcessActivity_close() {
    this.createProcessActivity_dialog = false;
  },
  createProcessActivity_save() {
    let body = this.createProcessActivity_currentItem;
    this.loading = true;
    this.$apiClient.restRequest("create", "processActivity", null, body)
    .then(response => {
        this.loading = false;
        this.createProcessActivity_dialog = false;
        this.initialize();
    }) .catch(error => {
        this.loading = false;
        console.log(error.response);
    });
  }
      }
  }
</script>