import App from './App.vue'
import Vue from 'vue'
import Vuex from 'vuex'
import store from './plugins/store'
import router from './router'
import './plugins/axios'
import vuetify from './plugins/vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuex)



new Vue({
    store,router,vuetify
    , render: h => h(App),
      beforeCreate() {
            this.$store.commit('initialiseStore');
        }
}).$mount('#app');