    export default class APIClient {
        constructor(axios, baseURL) {
                    this.axios = axios
                    if(baseURL) {
                        this.baseURL = baseURL
                    } else {
                        this.baseURL = "https://djbf741vm5.execute-api.ap-southeast-2.amazonaws.com/dev"
                    }

                }

        setToken(token) {
            this.accessToken = token
        }

        headers(authenticated = true) {
            var dict = {
                "Content-Type": "application/json"
            }
            if (authenticated) {
                dict["Authorization"] = "Bearer " + this.accessToken
            }
            return dict
        }

        login(data) {
            return this.axios({
                baseURL: this.baseURL,
                url: "/login",
                method: "POST",
                data: data,
                headers: this.headers(false)
              })
        }

        restRequest(what, entity, id, data, query) {
            if(!query) {
                query = ""
            }
            var url = '/' + entity
            if(id) {
                url += '/' + id
            }
            var method
            if (what == "create") {
                method = "POST"
            }

            else if (what == "update") {
                method = "PUT"
            }

            else if (what == "delete") {
                method = "DELETE"
            }

            else if (what == "get") {
                method = "GET"
            }

            return this.axios({
                baseURL: this.baseURL,
                url: url + "?" + query,
                method: method,
                data: data,
                headers: this.headers()
              })
        }

        request(method, endpoint, body, headers) {
            var h = this.headers()
            return this.axios({
                baseURL: this.baseURL,
                url: endpoint,
                method: method,
                data: body,
                headers: h
              })
        }
    }