<template>
    <div>
    <v-row no-gutters class="mt-n5" v-for="(item, index) in value" v-bind:key="item.id" align="center" justify="center">
        <v-col cols="8">
            <slot name="field1" v-bind:item="item"></slot>
        </v-col>
        <v-col cols="3">
            <slot name="field2" v-bind:item="item"></slot>
        </v-col>
        <v-col cols="1">
        <v-btn small color="error" dark @click="ma_remove(index)">-</v-btn>
        </v-col>
    </v-row>
    <v-btn small color="primary" dark class="mr-2" @click="ma_add">+</v-btn>
</div>
</template>
<script>
    export default {
    props: {
        defaultValue: Object, value: Array
    },
    computed: {

    },
    data: () => ({
        items: []
    }),
    watch: {
        value(newValue) {
            this.items = newValue
        }
    },
    methods: {
        ma_add() {
            if(this.value) {
                this.items = this.value
            } else {
                this.items = []
            }
            this.items.push(Object.assign({}, this.defaultValue))
            this.$emit('input', this.items)
        },
        ma_remove(index) {
            if(this.value) {
                this.items = this.value
            } else {
                this.items = []
            }
            this.items.splice(index, 1)
            this.$emit('input', this.items)
        }
    }
}
</script>