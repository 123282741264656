<template>
    <div>
        <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Create WorkOrder</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" lazy-validation>
            <v-row>
  <v-col v-if="false" cols="12" sm="12">
        <v-text-field v-model="currentItem.id" label="ID" type="text" :disabled="false"></v-text-field>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-text-field v-model="currentItem.name" label="Name" type="text" :disabled="false"></v-text-field>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-text-field v-model="currentItem.email" label="Email" type="text" :disabled="false"></v-text-field>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-text-field v-model="currentItem.phoneNumber" label="Phone Number" type="text" :disabled="false"></v-text-field>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-autocomplete
      :items="users"
      v-model="currentItem.user"
      item-text="email"
      label="User ID"
      @change=""
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small v-if="true" color="error" dark class="mr-2"  @click="Create_close()">Cancel</v-btn>
<v-btn small v-if="true" color="primary" dark class="mr-2"  @click="Create_save()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <div>
    <v-data-table
      :items-per-page="-1"
      :headers="tableHeaders"
      :items="items"
      class="elevation-1"
      :loading="loading"
      :search="search"

      :expanded.sync="expanded"

    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Persons</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
        
            <v-btn small v-if="true" color="primary" dark class="mr-2"  @click="createItem()">Create Person</v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.action="{ item }">
            <v-btn small v-if="true" color="primary" dark class="mr-2"  @click="createItem(item)">Edit</v-btn>
    <v-btn small v-if="true" color="error" dark class="mr-2"  @click="deleteItem(item)">Delete</v-btn>
      </template>
      <template v-slot:no-data>No Work Orders</template>
    </v-data-table>
  </div>
    </div>
</template>
<script>
    import Multipleadd2 from './multipleadd2.vue'
import Multipleadd from './multipleadd.vue'
    export default {
        components: {
            Multipleadd, Multipleadd2
        },
        props: {
            
        },
        data: () => ({
            users: [], dialog: false, currentItem: {user:{}}, loading: false,
search: null,
expanded: [],
items: []
        }),
        computed: {
            tableHeaders() {
return [
        {"text": "ID", "value": "id"},
    {"text": "Name", "value": "name"},
    {"text": "Email", "value": "email"},
    {"text": "Phone Number", "value": "phoneNumber"},
                { text: "Options", value: "action", sortable: false }].filter((x) => { return this.shouldShowHeader(x.value) })
        }
        },
        watch: {
            
        },
        created() {
            
    this.$apiClient.restRequest("get", "user", null, null, null)
    .then(response => {
      this.users = response.data
        
      this.loading = false
    }) .catch(error => {
        console.log(error.response);
    });






this.initialize();
        },
        methods: {
            Create_save() {
        let body = this.currentItem;
    this.loading = true;
    this.$apiClient.restRequest("create", "person", null, body)
    .then(response => {
        this.loading = false;
        this.dialog = false;
        this.initialize()
    }) .catch(error => {
        this.loading = false;
        console.log(error.response);
    });
},
Create_close() {
    this.dialog = false;
},
createItem(item) {
    this.dialog = true;
    this.currentItem = Object.assign({}, item);
    
},
    deleteItem(item) {
        confirm("Are you sure you want to delete this item?") && (() => {
        let id = item.id;
        this.loading = true;
        this.$apiClient .restRequest("delete", "person", id)
        .then(response => {
            this.loading = false; this.initialize();
        }) .catch(error => {
            this.loading = false;
            console.log(error.response.data.error_description);
        });
    })();
    },
shouldShowHeader(value) {
    if(value == 'id') {
    return true
}
if(value == 'name') {
    return true
}
if(value == 'email') {
    return true
}
if(value == 'phoneNumber') {
    return true
}
            else {
                return true
            }
        },
        initialize() {
            this.$apiClient.restRequest("get", "person", null, null, null)
.then(response => {
  this.items = response.data
  
  this.loading = false
}) .catch(error => {
    console.log(error.response);
});
        },
        }
    }
</script>