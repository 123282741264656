<template>
    <div>
        <div>
            <v-dialog v-model="createProcess_dialog" max-width="500px">
  <v-card>
    <v-card-title>
      <span class="headline">Create Process</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="form" lazy-validation>
            <v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-autocomplete
      :items="types"
      v-model="createProcess_currentItem.type"
      item-text="id"
      item-value="id"
      label="Type"
      @change=""
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="createProcess_currentItem.type.id == 'definition'" cols="12" sm="12">
        <v-text-field v-model="createProcess_currentItem.name" label="Name" type="text" :disabled="false"></v-text-field>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="createProcess_currentItem.type.id == 'instance'" cols="12" sm="12">
        <v-autocomplete
      :items="getInstanceItems()"
      v-model="createProcess_currentItem.type.instance"
      item-text="name"
      item-value="id"
      label="Instance"
      @change=""
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="createProcess_currentItem.type.id == 'instance'" cols="12" sm="12">
        <v-autocomplete
      :items="items"
      v-model="createProcess_currentItem.type.parent"
      item-text="name"
      item-value="id"
      label="Parent"
      @change=""
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
<v-row>
  <v-col cols="12" sm="12">
    Inputs
    <v-row v-for="(input, index) in createProcess_currentItem.inputs">
      <v-col cols="9" sm="9">
        <v-text-field
          label="Name"
          name="name"
          v-model="input.name"
          type="text"
          :disabled="input.process && input.process.id != createProcess_currentItem.id"
        ></v-text-field>
      </v-col>
        <v-col cols="12" sm="12">
    <v-autocomplete
      :items="['absolute', 'describedBy', 'reference']"
      label="Type"
      v-model="input.value.type.id"
      @change="onChangeValueType(createProcess_currentItem.inputs, index)"
      clearable
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="12" v-if="input.value.type.id == 'absolute'">
    <v-text-field
      label="Value"
      name="absoluteValueValue"
      v-model="input.value.type.absolute.value"
      type="text"
    ></v-text-field>
  </v-col>

  <v-col cols="12" sm="12" v-if="input.value.type.id == 'describedBy'">
    <v-text-field
      label="Value"
      name="describedByValueValue"
      v-model="input.value.type.describedBy.value"
      type="text"
    ></v-text-field>
  </v-col>

  <v-col cols="12" sm="12" v-if="input.value.type.id == 'reference'">
    <v-autocomplete
      :items="['input']"
      label="Reference Type"
      @change="onChangeReferenceType(createProcess_currentItem.inputs, index)"
      v-model="input.value.type.reference.type"
      clearable
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="12" v-if="input.value.type.reference.type == 'input'">
    <v-autocomplete
      :items="createProcess_currentItem.inputs"
      label="Input"
      v-model="input.value.type.reference.input"
      item-text="name"
      item-value="id"
      return-object
      clearable
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="12" v-if="input.value.type.reference.type == 'outcome'">
    <v-autocomplete
      :items="[]"
      label="Outcome"
      v-model="input.value.type.reference.outcome"
      item-text="displayName"
      item-value="id"
      return-object
      clearable
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="12">
    <v-text-field
      label="Transformed By"
      name="transformedBy"
      v-model="input.value.transformedBy"
      type="text"
    ></v-text-field>
  </v-col>
      <v-col>
        <v-btn small v-if="(input.id == null) || (input.process && input.process.id == createProcess_currentItem.id)" color="error" dark class="mt-6"
          @click="deleteInput(index)">-</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
    <v-btn small v-if="true" color="primary" dark @click="addInput">+</v-btn>
    </v-row>
  </v-col>
</v-row>

<v-row>
  <v-col cols="12" sm="12">
    Outcomes
    <v-row v-for="(outcome, index) in createProcess_currentItem.outcomes">
      <v-col cols="12" sm="12">
        <v-text-field
          label="Name"
          name="name"
          v-model="outcome.name"
          type="text"
        ></v-text-field>
      </v-col>

        <v-col cols="12" sm="12">
    <v-autocomplete
      :items="['absolute', 'describedBy', 'reference']"
      label="Type"
      v-model="outcome.value.type.id"
      @change="onChangeValueType(createProcess_currentItem.outcomes, index)"
      clearable
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="12" v-if="outcome.value.type.id == 'absolute'">
    <v-text-field
      label="Value"
      name="absoluteValueValue"
      v-model="outcome.value.type.absolute.value"
      type="text"
    ></v-text-field>
  </v-col>

  <v-col cols="12" sm="12" v-if="outcome.value.type.id == 'describedBy'">
    <v-text-field
      label="Value"
      name="describedByValueValue"
      v-model="outcome.value.type.describedBy.value"
      type="text"
    ></v-text-field>
  </v-col>

  <v-col cols="12" sm="12" v-if="outcome.value.type.id == 'reference'">
    <v-autocomplete
      :items="['input']"
      label="Reference Type"
      @change="onChangeReferenceType(createProcess_currentItem.outcomes, index)"
      v-model="outcome.value.type.reference.type"
      clearable
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="12" v-if="outcome.value.type.reference.type == 'input'">
    <v-autocomplete
      :items="createProcess_currentItem.inputs"
      label="Input"
      v-model="outcome.value.type.reference.input"
      item-text="name"
      item-value="id"
      return-object
      clearable
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="12" v-if="outcome.value.type.reference.type == 'outcome'">
    <v-autocomplete
      :items="[]"
      label="Outcome"
      v-model="outcome.value.type.reference.outcome"
      item-text="displayName"
      item-value="id"
      return-object
      clearable
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="12">
    <v-text-field
      label="Transformed By"
      name="transformedBy"
      v-model="outcome.value.transformedBy"
      type="text"
    ></v-text-field>
  </v-col>
    
      <v-col>
        <v-btn small v-if="true" color="error" dark class="mt-6"
          @click="deleteOutcome(index)">-</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
    <v-btn small v-if="true" color="primary" dark @click="addOutcome">+</v-btn>
    </v-row>
  </v-col>
</v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn small v-if="true" color="error" dark class="mr-2"  @click="createProcess_close()">Cancel</v-btn>
    <v-btn small v-if="true" color="primary" dark class="mr-2"  @click="createProcess_save()">Save</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
            <v-dialog v-model="addChild_dialog" max-width="500px">
  <v-card>
    <v-card-title>
      <span class="headline">Add Child Process</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="form" lazy-validation>
                <v-row>
  <v-col cols="12" sm="12">
    <v-autocomplete
      :items="items"
      label="Child"
      v-model="addChild_currentItem.child"
      item-text="name"
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
    </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn small v-if="true" color="error" dark class="mr-2"  @click="addChild_close()">Cancel</v-btn>
    <v-btn small v-if="true" color="primary" dark class="mr-2"  @click="addChild_save()">Save</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
            <v-dialog v-model="addTrigger_dialog" max-width="500px">
  <v-card>
    <v-card-title>
      <span class="headline">Add Process Trigger</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="form" lazy-validation>
                <v-row v-for="(trigger, i) in addTrigger_process.triggers">
    <v-row>
  <v-col cols="12" sm="12">
    <v-autocomplete
      :items="addTrigger_process.outcomes"
      label="Outcome"
      v-model="trigger.outcome"
      item-text="name"
      item-value="id"
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
    </v-row>
<v-row>
  <v-col cols="12" sm="12">
    <v-autocomplete
      :items="items"
      label="Triggers Process Instance"
      v-model="trigger.triggersInstance"
      @change="addTrigger_onTriggersProcessInstanceChange(i)"
      item-text="name"
      item-value="id"
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
    </v-row>
<v-row>
  <v-col cols="12" sm="12">
    Inputs Values
    <v-row v-for="(inputValue, j) in trigger.inputsValues">
      <v-col cols="12" sm="12">
        <v-autocomplete
          :items="trigger.triggersInstance.inputs"
          label="Input"
          v-model="inputValue.input"
          item-text="name"
          item-value="id"
          return-object
          clearable
        ></v-autocomplete>
      </v-col>
          <v-col cols="12" sm="12">
    <v-autocomplete
      :items="['absolute', 'describedBy', 'reference']"
      label="Type"
      v-model="inputValue.value.type.id"
      @change="onChangeValueType(trigger.inputsValues, j)"
      clearable
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="12" v-if="inputValue.value.type.id == 'absolute'">
    <v-text-field
      label="Value"
      name="absoluteValueValue"
      v-model="inputValue.value.type.absolute.value"
      type="text"
    ></v-text-field>
  </v-col>

  <v-col cols="12" sm="12" v-if="inputValue.value.type.id == 'describedBy'">
    <v-text-field
      label="Value"
      name="describedByValueValue"
      v-model="inputValue.value.type.describedBy.value"
      type="text"
    ></v-text-field>
  </v-col>

  <v-col cols="12" sm="12" v-if="inputValue.value.type.id == 'reference'">
    <v-autocomplete
      :items="['input','outcome']"
      label="Reference Type"
      @change="onChangeReferenceType(trigger.inputsValues, j)"
      v-model="inputValue.value.type.reference.type"
      clearable
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="12" v-if="inputValue.value.type.reference.type == 'input'">
    <v-autocomplete
      :items="addTrigger_process.inputs"
      label="Input"
      v-model="inputValue.value.type.reference.input"
      item-text="name"
      item-value="id"
      return-object
      clearable
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="12" v-if="inputValue.value.type.reference.type == 'outcome'">
    <v-autocomplete
      :items="addTrigger_availableOutcomes"
      label="Outcome"
      v-model="inputValue.value.type.reference.outcome"
      item-text="displayName"
      item-value="id"
      return-object
      clearable
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="12">
    <v-text-field
      label="Transformed By"
      name="transformedBy"
      v-model="inputValue.value.transformedBy"
      type="text"
    ></v-text-field>
  </v-col>
      <v-col>
        <v-btn small v-if="true" color="error" dark class="mt-6"
          @click="addTrigger_deleteInputValue(i, j)">Delete Input Value</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
    <v-btn small v-if="true" color="primary" dark @click="addTrigger_addInputValue(i)">Add Input Value</v-btn>
    </v-row>
  </v-col>
  <v-col>
    <v-btn small v-if="true" color="error" dark class="mt-6"
      @click="addTrigger_deleteOutcomeTrigger(i)">Delete Trigger</v-btn
    >
  </v-col>
</v-row>
</v-row>
    <v-btn small v-if="true" color="primary" dark @click="addTrigger_addTrigger">Add Trigger</v-btn>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn small v-if="true" color="error" dark class="mr-2"  @click="addTrigger_close()">Cancel</v-btn>
    <v-btn small v-if="true" color="primary" dark class="mr-2"  @click="addTrigger_save()">Save</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
            <v-dialog v-model="startProcess_dialog" max-width="500px">
  <v-card>
    <v-card-title>
      <span class="headline">Start Process</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="form" lazy-validation>
            <v-row>
  <v-col cols="12" sm="12">
    Inputs
    <v-row v-for="(input, index) in startProcess_currentItem.inputs">
      <v-col cols="9" sm="9">
        <v-text-field
          label="Name"
          name="name"
          v-model="input.name"
          type="text"
          :disabled="true"
        ></v-text-field>
      </v-col>
        <v-col cols="12" sm="12">
    <v-autocomplete
      :items="['absolute', 'describedBy', 'reference']"
      label="Type"
      v-model="input.value.type.id"
      @change="onChangeValueType(startProcess_currentItem.inputs, index)"
      clearable
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="12" v-if="input.value.type.id == 'absolute'">
    <v-text-field
      label="Value"
      name="absoluteValueValue"
      v-model="input.value.type.absolute.value"
      type="text"
    ></v-text-field>
  </v-col>

  <v-col cols="12" sm="12" v-if="input.value.type.id == 'describedBy'">
    <v-text-field
      label="Value"
      name="describedByValueValue"
      v-model="input.value.type.describedBy.value"
      type="text"
    ></v-text-field>
  </v-col>

  <v-col cols="12" sm="12" v-if="input.value.type.id == 'reference'">
    <v-autocomplete
      :items="['input']"
      label="Reference Type"
      @change="onChangeReferenceType(startProcess_currentItem.inputs, index)"
      v-model="input.value.type.reference.type"
      clearable
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="12" v-if="input.value.type.reference.type == 'input'">
    <v-autocomplete
      :items="startProcess_currentItem.inputs"
      label="Input"
      v-model="input.value.type.reference.input"
      item-text="name"
      item-value="id"
      return-object
      clearable
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="12" v-if="input.value.type.reference.type == 'outcome'">
    <v-autocomplete
      :items="[]"
      label="Outcome"
      v-model="input.value.type.reference.outcome"
      item-text="displayName"
      item-value="id"
      return-object
      clearable
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="12">
    <v-text-field
      label="Transformed By"
      name="transformedBy"
      v-model="input.value.transformedBy"
      type="text"
    ></v-text-field>
  </v-col>
    </v-row>
  </v-col>
</v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn small v-if="true" color="error" dark class="mr-2"  @click="startProcess_close()">Cancel</v-btn>
    <v-btn small v-if="true" color="primary" dark class="mr-2"  @click="startProcess_save()">Save</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
    <div>
    <v-data-table
      :items-per-page="-1"
      :headers="tableHeaders"
      :items="items"
      class="elevation-1"
      :loading="loading"
      :search="search"

    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Users</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
        
            <v-btn small v-if="true" color="primary" dark class="mr-2"  @click="createProcess_createItem()">Create Process</v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.action="{ item }">
            <v-btn small v-if="true" color="primary" dark class="mr-2"  @click="startProcess_createItem(item)">Start</v-btn>
            <v-btn small v-if="item.type.id == 'instance'" color="primary" dark class="mr-2"  @click="addTrigger_createItem(item)">Trigger</v-btn>
            <v-btn small v-if="true" color="primary" dark class="mr-2"  @click="addChild_createItem(item)">Child</v-btn>
            <v-btn small v-if="true" color="primary" dark class="mr-2"  @click="createProcess_createItem(item)">Edit</v-btn>
    <v-btn small v-if="true" color="error" dark class="mr-2"  @click="deleteItem(item)">Delete</v-btn>
      </template>
      <template v-slot:no-data>No Processes</template>
    </v-data-table>
  </div>
    </div>
    </div>
</template>
<script>
      import Multipleadd2 from './multipleadd2.vue'
  import Multipleadd from './multipleadd.vue'
  export default {
      components: {
          Multipleadd, Multipleadd2
      },
      props: {
          
      },
      data: () => ({
          loading: false,
          search: null,
          items: [],
          createProcess_currentItem: {inputs: [], outcomes: [], type: {}},
createProcess_dialog: false,
types:   [
    {id: 'definition', name: 'definition'},
    {id: 'instance', name: 'instance'}
  ],
addChild_currentItem: {},
addChild_dialog: false,
addTrigger_currentItem: {values: []},
addTrigger_dialog: false,
addTrigger_process: {},
addTrigger_availableOutcomes: [],
startProcess_currentItem: {},
startProcess_dialog: false
      }),
      computed: {
          tableHeaders() {
            return [
                {"text": "id", "value": "id"},
                {"text": "name", "value": "name"},
                {"text": "type", "value": "type.id"},
                {"text": "parentKeyPath", "value": "parentKeyPath"},
                {"text": "instanceKeyPath", "value": "instanceKeyPath"},
                { text: "Options", value: "action", sortable: false }
              ]
          },
          
      },
      created() {
        this.initialize();
      },
      methods: {
          onChangeValueType(array, index) {
        var item = array[index]
        if(item.value.type.id == 'absolute') {
            if(item.value.type.absolute) {}
            else { item.value.type.absolute = {} }
        } else if(item.value.type.id == 'describedBy') {
            if(item.value.type.describedBy) {}
            else { item.value.type.describedBy = {} }
        } else if(item.value.type.id == 'reference') {
            if(item.value.type.reference) {}
            else { item.value.type.reference = {} }
            if(item.value.type.reference.outcome) {}
            else { item.value.type.reference.outcome = {} }
            if(item.value.type.reference.input) {}
            else { item.value.type.reference.input = {} }
        }
        this.$set(array, index, item)
  },
  onChangeReferenceType(array, index) {
        var item = array[index]
        if(item.value.type.reference.type == 'input') {
            if(item.value.type.reference.input) {}
            else { item.value.type.reference.input = {} }
        } else if(item.value.type.reference.type == 'outcome') {
            if(item.value.type.reference.outcome) {}
            else { item.value.type.reference.outcome = {} }
        }
        this.$set(array, index, item)
  },
  getInstanceItems() {
    return this.items.filter((x) => {
        if(x.type && x.type.parent && x.type.parent.id) {
            return false
        } else {
            return true
        }
    })
  },
  addOutcome() {
    this.createProcess_currentItem.outcomes.push({value: {type: {reference: {}}}})
  },
  deleteOutcome(index) {
    let item = this.createProcess_currentItem.outcomes[index]
    var promise;
    if(item.id) {
        promise = this.$apiClient.restRequest("delete", "processOutcome/" + item.id)
        .then(response => {
        }).catch(error => {
            this.loading = false;
            console.log(error.response);
        });
    } else {
        promise = Promise.resolve()
    }
    return promise.then(this.createProcess_loadData)
  },
  addInput() {
    this.createProcess_currentItem.inputs.push({})
  },
  deleteInput(index) {
    let item = this.createProcess_currentItem.inputs[index]
    var promise;
    if(item.id) {
        promise = this.$apiClient.restRequest("delete", "processInput/" + item.id)
        .then(response => {
        }).catch(error => {
            this.loading = false;
            console.log(error.response);
        });
    } else {
        promise = Promise.resolve()
    }
    return promise.then(this.createProcess_loadData)
  },
  createProcess_loadData() {
    if(this.createProcess_currentItem.id) {
        this.$apiClient .restRequest("get", "process", this.createProcess_currentItem.id)
        .then(response => {
            var currentItem = response.data
            for(var i = 0; i < currentItem.outcomes.length; i++) {
                var item = currentItem.outcomes[i]
                if(item.value) {}
                else { item.value = {} }

                if(item.value.type) {}
                else { item.value.type = {} }

                if(item.value.type.absolute) {}
                else { item.value.type.absolute = {} }

                if(item.value.type.describedBy) {}
                else { item.value.type.describedBy = {} }

                if(item.value.type.reference) {}
                else { item.value.type.reference = {} }

                if(item.value.type.reference.outcome) {}
                else { item.value.type.reference.outcome = {} }

                if(item.value.type.reference.input) {}
                else { item.value.type.reference.input = {} }
                currentItem.outcomes[i] = item
            }

            for(var i = 0; i < currentItem.inputs.length; i++) {
                var item = currentItem.inputs[i]
                if(item.value) {}
                else { item.value = {} }

                if(item.value.type) {}
                else { item.value.type = {} }

                if(item.value.type.absolute) {}
                else { item.value.type.absolute = {} }

                if(item.value.type.describedBy) {}
                else { item.value.type.describedBy = {} }

                if(item.value.type.reference) {}
                else { item.value.type.reference = {} }

                if(item.value.type.reference.outcome) {}
                else { item.value.type.reference.outcome = {} }

                if(item.value.type.reference.input) {}
                else { item.value.type.reference.input = {} }
                currentItem.inputs[i] = item
            }
            
            this.createProcess_currentItem = currentItem
            
        }) .catch(error => {
            this.loading = false;
            console.log(error.response.data.error_description);
        });
    }
  },
createProcess_createItem(item) {
    this.createProcess_dialog = true;
    this.createProcess_currentItem = Object.assign({inputs: [], outcomes: [], type: {}}, item);
    this.createProcess_loadData();
},
  createProcess_close() {
    this.createProcess_dialog = false;
  },
  createProcess_save() {
    let body = this.createProcess_currentItem;
    this.loading = true;
    this.$apiClient.restRequest("create", "process", null, body)
    .then(response => {
        this.loading = false;
        this.createProcess_dialog = false;
        this.initialize();
    }) .catch(error => {
        this.loading = false;
        console.log(error.response);
    });
  },
        addChild_createItem(item) {
    this.addChild_dialog = true;
    this.addChild_currentItem = Object.assign({}, item);
    
},
  addChild_close() {
    this.addChild_dialog = false;
  },
  addChild_save() {
    let body = this.addChild_currentItem;
    this.loading = true;
    this.$apiClient.restRequest("create", "processChild", null, body)
    .then(response => {
        this.loading = false;
        this.addChild_dialog = false;
        this.initialize();
    }) .catch(error => {
        this.loading = false;
        console.log(error.response);
    });
  },
          onChangeValueType(array, index) {
        var item = array[index]
        if(item.value.type.id == 'absolute') {
            if(item.value.type.absolute) {}
            else { item.value.type.absolute = {} }
        } else if(item.value.type.id == 'describedBy') {
            if(item.value.type.describedBy) {}
            else { item.value.type.describedBy = {} }
        } else if(item.value.type.id == 'reference') {
            if(item.value.type.reference) {}
            else { item.value.type.reference = {} }
            if(item.value.type.reference.outcome) {}
            else { item.value.type.reference.outcome = {} }
            if(item.value.type.reference.input) {}
            else { item.value.type.reference.input = {} }
        }
        this.$set(array, index, item)
  },
  onChangeReferenceType(array, index) {
        var item = array[index]
        if(item.value.type.reference.type == 'input') {
            if(item.value.type.reference.input) {}
            else { item.value.type.reference.input = {} }
        } else if(item.value.type.reference.type == 'outcome') {
            if(item.value.type.reference.outcome) {}
            else { item.value.type.reference.outcome = {} }
        }
        this.$set(array, index, item)
  }
,addTrigger_updateItem(i) {
    var trigger = this.addTrigger_process.triggers[i]
    this.$apiClient .restRequest("get", "process", trigger.triggersInstance.id).then((response) => {
        this.addTrigger_process.triggers[i].triggersInstance = Object.assign({}, response.data)
    })
},
addTrigger_loadData(item) {
    this.$apiClient .restRequest("get", "process", item.id)
    .then(response => {
        var triggers = response.data.triggers
        for(var i = 0; i < triggers.length; i++) {
            var inputsValues = triggers[i].inputsValues
            for(var j = 0; j < inputsValues.length; j++) {
                var inputValue = inputsValues[j]
                    if(inputValue.value) {}
    else { inputValue.value = {} }
    if(inputValue.value.type) {}
    else { inputValue.value.type = {} }
    if(inputValue.value.type.absolute) {}
    else { inputValue.value.type.absolute = {} }
    if(inputValue.value.type.describedBy) {}
    else { inputValue.value.type.describedBy = {} }
    if(inputValue.value.type.reference) {}
    else { inputValue.value.type.reference = {} }
    if(inputValue.value.type.reference.outcome) {}
    else { inputValue.value.type.reference.outcome = {} }
    if(inputValue.value.type.reference.input) {}
    else { inputValue.value.type.reference.input = {} }
                inputsValues[j] = inputValue
            }
            triggers[i].inputsValues = inputsValues
        }
        response.data.triggers = triggers
        this.addTrigger_process = Object.assign({}, response.data)
        for(var i = 0; i < this.addTrigger_process.triggers.length; i++) {
            this.addTrigger_updateItem(i)
        }
    }) .catch(error => {
        this.loading = false;
        console.log(error.response.data.error_description);
    });

    this.$apiClient.restRequest("get", "processValueReferenceOutcomes", item.id)
    .then((response) => {
        this.addTrigger_availableOutcomes = response.data
    }) .catch(error => {
        this.loading = false;
        console.log(error.response.data.error_description);
    });
}
,addTrigger_onTriggersProcessInstanceChange(index) {
    this.addTrigger_updateItem(index)
    this.addTrigger_process.triggers[index].inputsValues = []
}
,addTrigger_addInputValue(index) {
    this.addTrigger_process.triggers[index].inputsValues.push({input: {}, value: {type: {absolute: {}, describedBy: {}, reference: {input: {}, outcome: {}}}}})
}
,addTrigger_addTrigger() {
    this.addTrigger_process.triggers.push({outcome: {}, triggersInstance: {}, inputsValues: []})
}
,addTrigger_deleteInputValue(i, j) {
    let item = this.addTrigger_process.triggers[i].inputsValues[j]
    var promise;
    if(item.id) {
        promise = this.$apiClient.restRequest("delete", "processOutcomeTriggerValue/" + item.id)
        .then(response => {
        }).catch(error => {
            this.loading = false;
            console.log(error.response);
        });
    } else {
        promise = Promise.resolve()
    }
    return promise.then(() => {
        this.addTrigger_process.triggers[i].inputsValues.splice(j, 1)
    })
}
,addTrigger_deleteOutcomeTrigger(i) {
    let item = this.addTrigger_process.triggers[i]
    var promise;
    if(item.id) {
        promise = this.$apiClient.restRequest("delete", "processOutcomeTrigger/" + item.id)
        .then(response => {
        }).catch(error => {
            this.loading = false;
            console.log(error.response);
        });
    } else {
        promise = Promise.resolve()
    }
    return promise.then(() => {
        this.addTrigger_process.triggers.splice(i, 1)
    })
}
,addTrigger_createItem(item) {
    this.addTrigger_dialog = true;
    this.addTrigger_currentItem = Object.assign({values: []}, item);
     this.addTrigger_currentItem = {
     instance: item
 }
 this.addTrigger_loadData(item);
},
  addTrigger_close() {
    this.addTrigger_dialog = false;
  },
  addTrigger_save() {
    let body = this.addTrigger_process;
    this.loading = true;
    this.$apiClient.restRequest("create", "processOutcomeTrigger", null, body)
    .then(response => {
        this.loading = false;
        this.addTrigger_dialog = false;
        this.initialize();
    }) .catch(error => {
        this.loading = false;
        console.log(error.response);
    });
  },
          startProcess_loadData() {
    this.$apiClient .restRequest("get", "processActivityInputs", this.startProcess_process.id)
    .then(response => {
        var inputs = response.data

        for(var i = 0; i < inputs.length; i++) {
            var item = inputs[i]
            if(item.value) {}
            else { item.value = {} }

            if(item.value.type) {}
            else { item.value.type = {} }

            if(item.value.type.absolute) {}
            else { item.value.type.absolute = {} }

            if(item.value.type.describedBy) {}
            else { item.value.type.describedBy = {} }

            if(item.value.type.reference) {}
            else { item.value.type.reference = {} }

            if(item.value.type.reference.outcome) {}
            else { item.value.type.reference.outcome = {} }

            if(item.value.type.reference.input) {}
            else { item.value.type.reference.input = {} }
            inputs[i] = item
        }

        this.startProcess_currentItem.inputs = inputs
        
    }) .catch(error => {
        this.loading = false;
        console.log(error.response.data.error_description);
    });
}
,startProcess_createItem(item) {
    this.startProcess_dialog = true;
    this.startProcess_currentItem = Object.assign({}, item);
        this.startProcess_process = item;
    this.startProcess_currentItem = {id: item.id, inputs: []};
    this.startProcess_loadData();
},
  startProcess_close() {
    this.startProcess_dialog = false;
  },
  startProcess_save() {
    let body = this.startProcess_currentItem;
    this.loading = true;
    this.$apiClient.restRequest("create", "processStart", null, body)
    .then(response => {
        this.loading = false;
        this.startProcess_dialog = false;
        
    }) .catch(error => {
        this.loading = false;
        console.log(error.response);
    });
  },
          deleteItem(item) {
              confirm("Are you sure you want to delete this item?") && (() => {
              let id = item.id;
              this.loading = true;
              this.$apiClient .restRequest("delete", "process", id)
              .then(response => {
                  this.loading = false; this.initialize();
              }) .catch(error => {
                  this.loading = false;
                  console.log(error.response.data.error_description);
              });
          })();
          },
          initialize() {
            this.$apiClient.restRequest("get", "process", null, null, null)
            .then(response => {
              this.items = response.data
              
              this.loading = false
            }) .catch(error => {
                console.log(error.response);
            });
          },
          startActivity(item) {
            this.$apiClient.restRequest("create", "processStart", null, {id: item.id}, null)
            .then(response => {
              
            }) .catch(error => {
                console.log(error.response);
            });
          }
      }
  }
</script>