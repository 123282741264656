    import Vue from 'vue'
    import Vuex from 'vuex'
    import jwt_decode from 'jwt-decode';


    Vue.use(Vuex)

    export default new Vuex.Store({
      state: {
        
        token: null,
        snackbar: {
            message: null,
            shown: false,
            color: "error"
        }
      },
      mutations: {
        saveItems(state, payload) {
          state[payload.entity] = payload.items
        },
        updateToken(state, payload) {
          state.token = payload
            var decoded = jwt_decode(payload);
            Vue.prototype.$role = decoded.role;
          localStorage.setItem('token', state.token)
        },
        recordError(state, payload) {
          state.snackbar = {
              message: payload,
              shown: true,
              color: "error"
          }
        },
        recordSuccess(state, payload) {
            state.snackbar = {
                message: payload,
                shown: true,
                color: "success"
            }
        },
        setSnackbarState(state, payload) {
            state.snackbar = {
                message: state.snackbar.message,
                shown: payload,
                color: state.snackbar.color
            }
        },
        initialiseStore(state) {
          state.token = null
                if(localStorage.getItem('token') != "undefined") {
                    state.token = localStorage.getItem('token')
                    var decoded = jwt_decode(state.token);
                    Vue.prototype.$role = decoded.role;
                }
        },
        clearStore(state) {
          localStorage.setItem('token', "undefined")
          state.token = null
        }
        
      },
      actions: {
      },
      modules: {
      }
    })