<template>
    <v-app id="inspire">
            <Login v-if="!isLoggedIn"></Login>
      <div v-if="isLoggedIn">
        <v-navigation-drawer v-model="drawer" app>
          <v-list dense>
            <router-link to="/user" v-if="$role == 'root' || $role == 'admin'">
  <v-list-item link>
    <v-list-item-action>
      <v-icon>mdi-home</v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>User</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</router-link>
<router-link to="/person" v-if="true">
  <v-list-item link>
    <v-list-item-action>
      <v-icon>mdi-home</v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>Person</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</router-link>
<router-link to="/activity" v-if="true">
  <v-list-item link>
    <v-list-item-action>
      <v-icon>mdi-home</v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>Activity</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</router-link>
<router-link to="/template" v-if="true">
  <v-list-item link>
    <v-list-item-action>
      <v-icon>mdi-home</v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>Template</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</router-link>
<router-link to="/activityshell" v-if="true">
  <v-list-item link>
    <v-list-item-action>
      <v-icon>mdi-home</v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>ActivityShell</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</router-link>
<router-link to="/process" v-if="true">
  <v-list-item link>
    <v-list-item-action>
      <v-icon>mdi-home</v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>ProcessList</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</router-link>
<router-link to="/processActivity" v-if="true">
  <v-list-item link>
    <v-list-item-action>
      <v-icon>mdi-home</v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>ProcessActivityList</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</router-link>
<router-link to="/element" v-if="true">
  <v-list-item link>
    <v-list-item-action>
      <v-icon>mdi-home</v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>ElementList</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</router-link>
          </v-list>
        </v-navigation-drawer>
        <v-app-bar app color="indigo" dark>
          <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
          <v-toolbar-title>Application</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-btn text large color="white" @click="logout">Logout</v-btn>
        </v-app-bar>
        <v-content>
          <router-view></router-view>
        </v-content>
        <v-footer color="indigo" app>
          <span class="white--text">&copy; 2020</span>
        </v-footer>
      </div>
  </v-app>
</template>
<script>
    import APIClient from "./helpers/APIClient"
        import Login from "@/components/login"
import Vue from 'vue'

export default {
    components: {
        Login
    },
    props: {
        source: String
    },
    data: () => ({
        drawer: null
    }),
    created() {
        Vue.prototype.$apiClient = new APIClient(this.$axios)
        
        this.initData()
    },
    methods: {
        initData() {
            this.$apiClient.setToken(this.$store.state.token)
          },
        logout() {
            this.$store.commit('clearStore')
          }
    },
    computed: {
      token() {
        return this.$store.state.token
      },
      isLoggedIn() {
        return this.token != null
      }
    },
    watch: {
      token(value) {
        this.initData()
      }
    }
};
</script>