<template>
    <div>
        <div>
            <v-dialog v-model="elementForm_dialog" max-width="500px">
  <v-card>
    <v-card-title>
      <span class="headline">Element Form</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="form" lazy-validation>
            <v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-text-field v-model="elementForm_currentItem.name" label="Name" type="text" :disabled="false"></v-text-field>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-textarea v-model="elementForm_currentItem.description" label="Description" :disabled="false" auto-grow></v-textarea>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-autocomplete
      :items="elementForm_itemTypes"
      v-model="elementForm_currentItem.typeID"
      item-text="name"
      item-value="id"
      label="Type"
      @change=""
      
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-autocomplete
      :items="elementForm_items"
      v-model="elementForm_currentItem.parentID"
      item-text="name"
      item-value="id"
      label="Parent"
      @change=""
      
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
    <v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-autocomplete
      :items="elementForm_entitys"
      v-model="elementForm_currentItem.entityID"
      item-text="name"
      item-value="id"
      label="Entity"
      @change=""
      
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
    <v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-autocomplete
      :items="elementForm_activities"
      v-model="elementForm_currentItem.createdByActivityID"
      item-text="name"
      item-value="id"
      label="Activity"
      @change=""
      
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn small v-if="true" color="error" dark class="mr-2"  @click="elementForm_close()">Cancel</v-btn>
    <v-btn small v-if="true" color="primary" dark class="mr-2"  @click="elementForm_save()">Save</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
    <div>
    <v-toolbar flat color="white">
      <v-toolbar-title>Elements</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-text-field
    v-model="search"
    append-icon="search"
    label="Search"
    single-line
    hide-details
  ></v-text-field>
  <v-spacer></v-spacer>
    
        <v-btn small v-if="true" color="primary" dark class="mr-2"  @click="elementForm_createItem">Create Element</v-btn>
    </v-toolbar>

    <v-data-table
      :items-per-page="-1"
      :headers="tableHeaders"
      :items="items"
      class="elevation-1"
      :loading="loading"
      :search="search"
    >

      <template v-slot:item.action="{ item }">
            <v-btn small v-if="true" color="primary" dark class="mr-2"  @click="elementForm_createItem(item)">Edit</v-btn>
    <v-btn small v-if="true" color="error" dark class="mr-2"  @click="deleteItem(item)">Delete</v-btn>
      </template>
      <template v-slot:no-data>No Elements</template>
    </v-data-table>
  </div>
    </div>
    </div>
</template>
<script>
      import Multipleadd2 from './multipleadd2.vue'
  import Multipleadd from './multipleadd.vue'
  export default {
      components: {
          Multipleadd, Multipleadd2
      },
      props: {
          
      },
      data: () => ({
          loading: false,
          search: null,
          items: [],
          elementForm_currentItem: {},
elementForm_dialog: false,
elementForm_items: [],
elementForm_itemTypes: [{id: 1, name: "organization"}, {id: 2, name: "project"}, {id: 3, name: "application"}, {id: 4, name: "database"}, {id: 5, name: "table"}, {id: 6, name: "tableField"}, {id: 7, name: "route"}, {id: 8, name: "screen"}, {id: 9, name: "view"}, {id: 10, name: "code"}, {id: 11, name: "entity"}, {id: 12, name: "generic"}],
elementForm_activities: []
      }),
      watch: {

      },
      computed: {
          tableHeaders() {
            return [
                {"text": "id", "value": "id"},
                {"text": "name", "value": "name"},
                {"text": "type", "value": "typeID"},
                {"text": "keyPath", "value": "elementKeyPath"},
                { text: "Options", value: "action", sortable: false }
              ]
          },
          elementForm_organizations() {
    return this.elementForm_items.filter((x) => { return x.typeID == 1 })
},
elementForm_projects() {
    return this.elementForm_items.filter((x) => { return x.typeID == 2 })
},
elementForm_applications() {
    return this.elementForm_items.filter((x) => { return x.typeID == 3 })
},
elementForm_databases() {
    return this.elementForm_items.filter((x) => { return x.typeID == 4 })
},
elementForm_tables() {
    return this.elementForm_items.filter((x) => { return x.typeID == 5 })
},
elementForm_tableFields() {
    return this.elementForm_items.filter((x) => { return x.typeID == 6 })
},
elementForm_routes() {
    return this.elementForm_items.filter((x) => { return x.typeID == 7 })
},
elementForm_screens() {
    return this.elementForm_items.filter((x) => { return x.typeID == 8 })
},
elementForm_views() {
    return this.elementForm_items.filter((x) => { return x.typeID == 9 })
},
elementForm_codes() {
    return this.elementForm_items.filter((x) => { return x.typeID == 10 })
},
elementForm_entitys() {
    return this.elementForm_items.filter((x) => { return x.typeID == 11 })
},
elementForm_generics() {
    return this.elementForm_items.filter((x) => { return x.typeID == 12 })
}
          
      },
      created() {
        this.initialize();
      },
      methods: {
          deleteItem(item) {
              confirm("Are you sure you want to delete this item?") && (() => {
              let id = item.id;
              this.loading = true;
              this.$apiClient .restRequest("delete", "element", id)
              .then(response => {
                  this.loading = false; this.initialize();
              }) .catch(error => {
                  this.loading = false;
                  console.log(error)
                  console.log(error.response.data.error_description);
              });
          })();
          },
          initialize() {
            this.items = []
              this.$apiClient .restRequest("get", "element")
              .then(response => {
                  this.items = response.data
              }) .catch(error => {
                  this.loading = false;
                  console.log(error)
                  console.log(error.response.data.error_description);
              });
          },
          elementForm_initForm() {
      this.$apiClient .restRequest("get", "element")
      .then(response => {
          this.elementForm_items = response.data
      }) .catch(error => {
          this.loading = false;
          console.log(error)
          console.log(error.response.data.error_description);
      });

      this.$apiClient .restRequest("get", "processActivity")
      .then(response => {
          this.elementForm_activities = response.data
      }) .catch(error => {
          this.loading = false;
          console.log(error)
          console.log(error.response.data.error_description);
      });
},
elementForm_createItem(item) {
    this.elementForm_dialog = true;
    this.elementForm_currentItem = Object.assign({}, item);
    this.elementForm_initForm()
},
  elementForm_close() {
    this.elementForm_dialog = false;
  },
  elementForm_save() {
    let body = this.elementForm_currentItem;
    this.loading = true;
    this.$apiClient.restRequest("create", "element", null, body)
    .then(response => {
        this.loading = false;
        this.elementForm_dialog = false;
        this.initialize();
    }) .catch(error => {
        this.loading = false;
        console.log(error.response);
    });
  }
      }
  }
</script>