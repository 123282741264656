<template>
    <div>
    <v-autocomplete :items="items" v-model="parentActivities" return-object multiple chips deletable-chips item-text="name" item-value="id">

    </v-autocomplete>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Create WorkOrder</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" lazy-validation>
            <v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-text-field v-model="currentItem.name" label="Name" type="text" :disabled="false"></v-text-field>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-textarea v-model="currentItem.details" label="Details" :disabled="false" auto-grow></v-textarea>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-autocomplete
      :items="statuses"
      v-model="currentItem.status"
      item-text="name"
      label="Status"
      @change=""
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-text-field v-model="currentItem.order" label="Order" type="text" :disabled="false"></v-text-field>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-autocomplete
      :items="activityShells"
      v-model="currentItem.activityShell"
      item-text="name"
      label="Activity Shell"
      @change=""
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-autocomplete
      :items="items"
      v-model="currentItem.parentActivity"
      item-text="displayText"
      label="Parent Activity"
      @change=""
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-autocomplete
      :items="currentItem.outcomes"
      v-model="currentItem.outcome"
      item-text="name"
      label="Outcome"
      @change=""
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small v-if="true" color="error" dark class="mr-2"  @click="Create_close()">Cancel</v-btn>
<v-btn small v-if="true" color="primary" dark class="mr-2"  @click="Create_save()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <div>
    <v-data-table
      :items-per-page="-1"
      :headers="tableHeaders"
      :items="filteredItems"
      class="elevation-1"
      :loading="loading"
      :search="search"

      :expanded.sync="expanded"

    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Activities</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
        <v-combobox
    dense
  multiple
  small-chips
  :items="statuses"
    item-text="name"
    item-value="id"
    v-model="selectedStatuses"
></v-combobox>
            <v-btn small v-if="true" color="primary" dark class="mr-2"  @click="createItem()">Create Activity</v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.action="{ item }">
            <v-btn small v-if="hasParent(item)" color="primary" dark class="mr-2"  @click="compressItem(item)"><</v-btn>
    <v-btn small v-if="hasChildren(item)" color="primary" dark class="mr-2"  @click="expandItem(item)">></v-btn>
    <v-btn small v-if="true" color="primary" dark class="mr-2"  @click="addChild(item)">+</v-btn>
    <v-btn small v-if="true" color="primary" dark class="mr-2"  @click="createItem(item)">Edit</v-btn>
    <v-btn small v-if="true" color="error" dark class="mr-2"  @click="deleteItem(item)">Delete</v-btn>
      </template>
      <template v-slot:no-data>No Work Orders</template>
    </v-data-table>
  </div>
    </div>
</template>
<script>
    import Multipleadd2 from './multipleadd2.vue'
import Multipleadd from './multipleadd.vue'
    export default {
        components: {
            Multipleadd, Multipleadd2
        },
        props: {
            
        },
        data: () => ({
            statuses: [], activityShells: [], activityExecutors: [], activityOutcomes: [], filteredItems: [], parentActivities: [], statuses: [], selectedStatuses: [], dialog: false, currentItem: {}, loading: false,
search: null,
expanded: [],
items: []
        }),
        computed: {
            parentActivityIDs() {
    return this.parentActivities.map((x) => { return x.id })
},
tableHeaders() {
return [
        {"text": "ID", "value": "id"},
    {"text": "Name", "value": "name"},
    {"text": "Type", "value": "type"},
    {"text": "Parent", "value": "parentActivity.name"},
    {"text": "Path", "value": "path"},
    {"text": "Status", "value": "status.name"},
                { text: "Options", value: "action", sortable: false }].filter((x) => { return this.shouldShowHeader(x.value) })
        }
        },
        watch: {
            selectedStatuses(newValue) {
    localStorage.selectedStatuses = JSON.stringify(this.selectedStatuses)
    this.applyFilter()
},
parentActivities() {
  this.applyFilter()
}
        },
        created() {
            
    this.$apiClient.restRequest("get", "status", null, null, null)
    .then(response => {
      this.statuses = response.data
        
      this.loading = false
    }) .catch(error => {
        console.log(error.response);
    });
    this.$apiClient.restRequest("get", "activityShell", null, null, null)
    .then(response => {
      this.activityShells = response.data
        
      this.loading = false
    }) .catch(error => {
        console.log(error.response);
    });
    this.$apiClient.restRequest("get", "activityExecutor", null, null, null)
    .then(response => {
      this.activityExecutors = response.data
        
      this.loading = false
    }) .catch(error => {
        console.log(error.response);
    });
    this.$apiClient.restRequest("get", "activityOutcome", null, null, null)
    .then(response => {
      this.activityOutcomes = response.data
        
      this.loading = false
    }) .catch(error => {
        console.log(error.response);
    });
if(localStorage.selectedStatuses) {
    this.selectedStatuses = JSON.parse(localStorage.selectedStatuses)
}

if(localStorage.parentActivities) {
    this.parentActivities = JSON.parse(localStorage.parentActivities)
}










this.initialize();
        },
        methods: {
            rootActivities() {
    return this.items.filter((x) => { return x.parentActivity.id == null })
},
applyFilter() {
    var sort = function(a, b){
        if(a.parentActivity.id < b.parentActivity.id) {
            return -1
        }
        if(b.parentActivity.id < a.parentActivity.id) {
            return 1
        }
        if(a.parentActivity.id == b.parentActivity.id) {
            return 0
        }
    }

    if(this.parentActivityIDs.length > 0) {
        this.filteredItems = this.items.filter((x) => {
        return this.parentActivityIDs.includes(x.parentActivity.id) && !this.parentActivityIDs.includes(x.id)
        })
    } else {
        this.filteredItems = this.rootActivities()
    }
    localStorage.parentActivities = JSON.stringify(this.parentActivities)

    if(this.selectedStatuses.length > 0) {
        this.filteredItems = this.filteredItems.filter((i) => {
         return this.selectedStatuses.map((x) => { return x.id }).includes(i.status.id)
        })
    }
},
hasParent(item) {
var hide = item.parentActivity.id == null || (this.filteredItems.filter((x) => { return x.id == item.parentActivity.id }).length != 0)
    return !hide
},
hasChildren(item) {
    return this.items.filter((x) => { return x.parentActivity.id == item.id }).length != 0
},
Create_save() {
        let body = this.currentItem;
    this.loading = true;
    this.$apiClient.restRequest("create", "activity", null, body)
    .then(response => {
        this.loading = false;
        this.dialog = false;
        this.initialize()
    }) .catch(error => {
        this.loading = false;
        console.log(error.response);
    });
},
Create_close() {
    this.dialog = false;
},
expandItem(item) {
    this.parentActivities.push(item)
    this.applyFilter()
},
compressItem(item) {
    this.parentActivities = this.parentActivities.filter((x) => { return x.id != item.parentActivity.id })
    this.applyFilter()
},
removeItem(item) {
    this.parentActivities = this.parentActivities.filter((x) => { return x.id != item.id })
    this.applyFilter()
},
addChild(item) {
    this.dialog = true;
    
    this.currentItem = {
        parentActivity: Object.assign({}, item)
    };
},
createItem(item) {
    this.dialog = true;
    this.currentItem = Object.assign({}, item);
    if(this.currentItem.activityShell) {
    this.$apiClient.restRequest("get", "activityShellOutcome", null, null, "activityShellID=" + this.currentItem.activityShell.id).then((response) => {
        this.currentItem.outcomes = response.data
    })
}
},
    deleteItem(item) {
        confirm("Are you sure you want to delete this item?") && (() => {
        let id = item.id;
        this.loading = true;
        this.$apiClient .restRequest("delete", "activity", id)
        .then(response => {
            this.loading = false; this.initialize();
        }) .catch(error => {
            this.loading = false;
            console.log(error.response.data.error_description);
        });
    })();
    },
shouldShowHeader(value) {
    if(value == 'id') {
    return true
}
if(value == 'name') {
    return true
}
if(value == 'type') {
    return true
}
if(value == 'parentActivity.name') {
    return true
}
if(value == 'path') {
    return true
}
if(value == 'status.name') {
    return true
}
            else {
                return true
            }
        },
        initialize() {
            this.$apiClient.restRequest("get", "activity", null, null, null)
.then(response => {
  this.items = response.data
  this.applyFilter()
  this.loading = false
}) .catch(error => {
    console.log(error.response);
});
        },
        }
    }
</script>