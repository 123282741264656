<template>
    <v-content>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12">
              <v-toolbar
                color="primary"
                dark
                flat
              >
                <v-toolbar-title>Login form</v-toolbar-title>

              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="Email"
                    name="email"
                    v-model="email"
                    prepend-icon="person"
                    type="text"
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    v-model="password"
                    label="Password"
                    name="password"
                    prepend-icon="lock"
                    type="password"
                    v-on:keyup.enter="loginClick"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="loginClick" :loading="loading">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
</template>

<script>
import Vue from 'vue';

  export default {
    data: () => ({
      email: null,
      password: null,
      loading: false
    }),
    computed: {

    },
    methods: {
      loginClick() {
        var body = {
          email: this.email,
          password: this.password
        }
        this.loading = true
        this.$apiClient.login(body)
.then(    (response) => {
      this.loading = false
      this.$store.commit("updateToken", response.data.token)
    })
        .catch((error) => {
            console.log(error)
           this.loading = false
         })
      }
    }
  }
</script>