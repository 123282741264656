<template>
    <div>
    <v-autocomplete :items="items" v-model="selectedActivityShells" return-object multiple chips deletable-chips item-text="name" item-value="id">

    </v-autocomplete>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Create WorkOrder</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" lazy-validation>
            <v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-autocomplete
      :items="items"
      v-model="currentItem.reference"
      item-text="displayName"
      label="Reference Activity Shell"
      @change="updateForm"
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
    <multipleadd v-model="currentItem.keys" :defaultValue="null">
    <template v-slot:field1="{ item }">
        <v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-text-field v-model="item.key" label="Name" type="text" :disabled="false"></v-text-field>
  </v-col>
</v-row>
    </template>
    <template v-slot:field2="{ item }">
        <v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-text-field v-model="item.key" label="Name" type="text" :disabled="false"></v-text-field>
  </v-col>
</v-row>
    </template>
</multipleadd>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
    <multipleadd v-model="currentItem.values" :defaultValue="null">
    <template v-slot:field1="{ item }">
        <v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-autocomplete
      :items="currentItem.referenceKeys"
      v-model="item.key"
      item-text="key"
      label="Name"
      @change=""
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
    </template>
    <template v-slot:field2="{ item }">
        <v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-text-field v-model="item.value" label="Value" type="text" :disabled="false"></v-text-field>
  </v-col>
</v-row>
    </template>
</multipleadd>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-text-field v-model="currentItem.name" label="Name" type="text" :disabled="currentItem.reference != null"></v-text-field>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-textarea v-model="currentItem.details" label="Details" :disabled="currentItem.reference != null" auto-grow></v-textarea>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-autocomplete
      :items="items"
      v-model="currentItem.parent"
      item-text="displayName"
      label="Parent Activity Shell"
      @change=""
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-autocomplete
      :items="activityExecutors"
      v-model="currentItem.assignedTo"
      item-text="name"
      label="Assigned To"
      @change=""
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-text-field v-model="currentItem.type" label="Type" type="text" :disabled="false"></v-text-field>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-text-field v-model="currentItem.order" label="Order" type="text" :disabled="false"></v-text-field>
  </v-col>
</v-row>
<v-row>
  <v-col v-if="true" cols="12" sm="12">
    <multipleadd v-model="currentItem.outcomes" :defaultValue="null">
    <template v-slot:field1="{ item }">
        <v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-text-field v-model="item.name" label="Name" type="text" :disabled="false"></v-text-field>
  </v-col>
</v-row>
    </template>
    <template v-slot:field2="{ item }">
        <v-row>
  <v-col v-if="true" cols="12" sm="12">
        <v-autocomplete
      :items="items"
      v-model="item.triggersActivityShell"
      item-text="displayName"
      label="Triggers"
      @change=""
      return-object
      clearable
    ></v-autocomplete>
  </v-col>
</v-row>
    </template>
</multipleadd>
  </v-col>
</v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small v-if="true" color="error" dark class="mr-2"  @click="Create_close()">Cancel</v-btn>
<v-btn small v-if="true" color="primary" dark class="mr-2"  @click="Create_save()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <div>
    <v-data-table
      :items-per-page="-1"
      :headers="tableHeaders"
      :items="filteredItems"
      class="elevation-1"
      :loading="loading"
      :search="search"

      :expanded.sync="expanded"

    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Activity Shells</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
        
            <v-btn small v-if="true" color="primary" dark class="mr-2"  @click="createItem()">Create ActivityShell</v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.action="{ item }">
            <v-btn small v-if="true" color="primary" dark class="mr-2"  @click="createItem(item)">Edit</v-btn>
    <v-btn small v-if="true" color="error" dark class="mr-2"  @click="deleteItem(item)">Delete</v-btn>
      </template>
      <template v-slot:no-data>No Work Orders</template>
    </v-data-table>
  </div>
    </div>
</template>
<script>
    import Multipleadd2 from './multipleadd2.vue'
import Multipleadd from './multipleadd.vue'
    export default {
        components: {
            Multipleadd, Multipleadd2
        },
        props: {
            
        },
        data: () => ({
            activityExecutors: [], selectedActivityShells: [], filteredItems: [], dialog: false, currentItem: {}, loading: false,
search: null,
expanded: [],
items: []
        }),
        computed: {
            selectedParentActivityShellIDs() {
    return this.selectedActivityShells.map((x) => { return x.id })
},
tableHeaders() {
return [
        {"text": "ID", "value": "id"},
    {"text": "Name", "value": "name"},
    {"text": "Assigned To", "value": "assignedTo.name"},
    {"text": "Type", "value": "type"},
    {"text": "Order", "value": "order"},
    {"text": "Order Path", "value": "orderPath"},
                { text: "Options", value: "action", sortable: false }].filter((x) => { return this.shouldShowHeader(x.value) })
        }
        },
        watch: {
            selectedActivityShells() {
    this.applyFilter()
}
        },
        created() {
            
    this.$apiClient.restRequest("get", "activityExecutor", null, null, null)
    .then(response => {
      this.activityExecutors = response.data
        
      this.loading = false
    }) .catch(error => {
        console.log(error.response);
    });












this.initialize();
        },
        methods: {
              updateForm() {
    this.$apiClient.restRequest("get", "activityShellOutcome", null, null, "activityShellID=" + this.currentItem.id).then((response) => {
        this.currentItem.outcomes = response.data
    })
    if(this.currentItem.reference && this.currentItem.reference.id) {
        if(this.currentItem.values) {} else { return }
      var values = this.currentItem.values
          for(var i = 0; i < values.length; i++) {
              var replacementString = "{{" + values[i].key.key + "}}"
              if(this.currentItem.name) {
                  this.currentItem.name = this.currentItem.name.replace(replacementString, values[i].value)
              }

              if(this.currentItem.details) {
                  this.currentItem.details = this.currentItem.details.replace(replacementString, values[i].value)
              }
              
              if(this.currentItem.displayName) {
                  this.currentItem.displayName = this.currentItem.displayName.replace(replacementString, values[i].value)
              }
          }
    }
  },
applyFilter() {
    if(this.selectedParentActivityShellIDs.length > 0) {
        this.filteredItems = this.items.filter((x) => {
            if(x.parent) {
                return this.selectedParentActivityShellIDs.includes(x.parent.id) && !this.selectedParentActivityShellIDs.includes(x.id)
            } else {
                return false
            }
        })
    } else {
        this.filteredItems = this.items
    }
},
Create_save() {
        let body = this.currentItem;
    this.loading = true;
    this.$apiClient.restRequest("create", "activityshell", null, body)
    .then(response => {
        this.loading = false;
        this.dialog = false;
        this.initialize()
    }) .catch(error => {
        this.loading = false;
        console.log(error.response);
    });
},
Create_close() {
    this.dialog = false;
},
createItem(item) {
    this.dialog = true;
    this.currentItem = Object.assign({}, item);
    this.currentItem = Object.assign({outcomes: []}, item)
this.updateForm()
},
    deleteItem(item) {
        confirm("Are you sure you want to delete this item?") && (() => {
        let id = item.id;
        this.loading = true;
        this.$apiClient .restRequest("delete", "activityshell", id)
        .then(response => {
            this.loading = false; this.initialize();
        }) .catch(error => {
            this.loading = false;
            console.log(error.response.data.error_description);
        });
    })();
    },
shouldShowHeader(value) {
    if(value == 'id') {
    return true
}
if(value == 'name') {
    return true
}
if(value == 'assignedTo.name') {
    return true
}
if(value == 'type') {
    return true
}
if(value == 'order') {
    return true
}
if(value == 'orderPath') {
    return true
}
            else {
                return true
            }
        },
        initialize() {
            this.$apiClient.restRequest("get", "activityshell", null, null, null)
.then(response => {
  this.items = response.data
  this.applyFilter()
  this.loading = false
}) .catch(error => {
    console.log(error.response);
});
        },
        }
    }
</script>