import Vue from 'vue'
import VueRouter from 'vue-router'
import C0 from '@/components/UserView'
import C1 from '@/components/PersonView'
import C2 from '@/components/ActivityView'
import C3 from '@/components/TemplateView'
import C4 from '@/components/ActivityShellView'
import C5 from '@/components/ProcessList'
import C6 from '@/components/ProcessActivityList'
import C7 from '@/components/ElementList'

Vue.use(VueRouter)

const routes = [
    {
    path: '/user',
    name: 'User',
    component: C0
  },
  {
    path: '/person',
    name: 'Person',
    component: C1
  },
  {
    path: '/activity',
    name: 'Activity',
    component: C2
  },
  {
    path: '/template',
    name: 'Template',
    component: C3
  },
  {
    path: '/activityshell',
    name: 'ActivityShell',
    component: C4
  },
  {
    path: '/process',
    name: 'ProcessList',
    component: C5
  },
  {
    path: '/processActivity',
    name: 'ProcessActivityList',
    component: C6
  },
  {
    path: '/element',
    name: 'ElementList',
    component: C7
  }
]

const router = new VueRouter({
  
  routes: routes
})

export default router